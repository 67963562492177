import { useMsal } from '@azure/msal-react';
import { AuthHelper } from '../auth/AuthHelper';
import { LogEventType, LogService } from '../services/LogService';

export const useLog = () => {
    const { instance, inProgress } = useMsal();
    const logService = new LogService();

    const writeLog = async (
        eventType: LogEventType,
        triggeredBy: string | null = null,
        triggeredFor: string | null = null,
    ) => {
        await logService.writeLog(
            eventType,
            triggeredBy,
            triggeredFor,
            await AuthHelper.getSKaaSAccessToken(instance, inProgress),
        );
    };

    const readLogs = async (eventType: LogEventType, from: Date, to: Date) => {
        return await logService.readLogs(
            eventType,
            from,
            to,
            await AuthHelper.getSKaaSAccessToken(instance, inProgress),
        );
    };

    return {
        writeLog,
        readLogs,
    };
};
