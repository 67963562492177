import {
    Table,
    makeStyles,
    shorthands,
    tokens,
    TableHeader,
    TableRow,
    TableBody,
    TableCell,
    TableHeaderCell,
    Divider,
    TagPicker,
    TagPickerList,
    TagPickerInput,
    TagPickerControl,
    TagPickerProps,
    TagPickerOption,
    TagPickerGroup,
    useTagPickerFilter,
    Avatar,
    Tag,
    Button,
    Tooltip,
} from '@fluentui/react-components';
import React from 'react';
import { useLog } from '../../libs/hooks';
import { LogEventType, ReadLogsResponseResultRecord } from '../../libs/services/LogService';
import {
    CartesianGrid,
    Line,
    LineChart,
    ResponsiveContainer,
    Tooltip as ChartTooltip,
    TooltipProps as ChartTooltipProps,
    XAxis,
    YAxis,
} from 'recharts';
import { Loading } from '../views';
import { KpiDefinition } from './KpiWindow';
import * as XLSX from 'xlsx';
import { ArrowDownloadRegular } from '@fluentui/react-icons';

export interface KpiPanelProps {
    kpi: KpiDefinition;
    from: Date;
    to: Date;
}

const useClasses = makeStyles({
    root: {
        width: '100%',
        ...shorthands.margin('12px'),
    },
    header: {
        fontSize: tokens.fontSizeBase500,
        fontWeight: tokens.fontWeightMedium,
        lineHeight: tokens.fontSizeHero900,
    },
    title: {
        textAlign: 'center',
    },
    divider: {
        ...shorthands.padding('24px', 0),
    },
    tableToolbar: {
        display: 'flex',
        flexDirection: 'row',
        ...shorthands.gap('4px'),
    },
});

interface SeriesEntry {
    d: number;

    [key: string]: number | undefined;
}

const lineColors = [
    '#993300',
    '#333300',
    '#003300',
    '#003366',
    '#333399',
    '#333333',
    '#800000',
    '#FF6600',
    '#808000',
    '#008000',
    '#008080',
    '#666699',
    '#808080',
    '#339966',
    '#33CCCC',
    '#3366FF',
    '#800080',
    '#969696',
    '#FFCC00',
    '#993366',
];

function getColor(index: number): string {
    return lineColors[index] ?? lineColors[lineColors.length - 1];
}

function tickFormatter(value: number): string {
    const date = new Date(value);
    return `${(date.getMonth() + 1).toString().padStart(2, '0')}-${date.getDate().toString().padStart(2, '0')}`;
}

function labelFormatter(value: number): string {
    const date = new Date(value);
    return `${date.getFullYear()}-${(date.getMonth() + 1).toString().padStart(2, '0')}-${date.getDate().toString().padStart(2, '0')}`;
}

function dateFormatter(date: Date): string {
    return `${date.getFullYear()}-${(date.getMonth() + 1).toString().padStart(2, '0')}-${date.getDate().toString().padStart(2, '0')}`;
}

function generateEmptySeriesDataForRange(from: Date, to: Date): SeriesEntry[] {
    const list: SeriesEntry[] = [];
    for (const date = new Date(from); date < to; date.setDate(date.getDate() + 1)) {
        list.push({
            d: date.setHours(0, 0, 0, 0),
        });
    }
    return list;
}

function getSeriesListFromChartData(seriesEntries: SeriesEntry[]): string[] {
    const list: string[] = [];

    seriesEntries.forEach((entry) => {
        Object.keys(entry).forEach((key) => {
            if (key !== 'd' && !list.includes(key)) {
                list.push(key);
            }
        });
    });

    return list;
}

export const KpiPanel: React.FC<KpiPanelProps> = ({ kpi, to, from }) => {
    const classes = useClasses();

    const log = useLog();

    const [chartData, setChartData] = React.useState<SeriesEntry[]>([]);
    const [tableData, setTableData] = React.useState<ReadLogsResponseResultRecord[]>([]);
    const [filteredTableData, setFilteredTableData] = React.useState<ReadLogsResponseResultRecord[]>([]);
    const [filterDimension, setFilterDimension] = React.useState<string[]>([]);
    const [isLoading, setIsLoading] = React.useState<boolean>(false);

    const keepDimensions = kpi.type !== LogEventType.UserActive;

    React.useEffect(() => {
        setIsLoading(true);
        log.readLogs(kpi.type, from, to)
            .then((r) => {
                setFilterDimension([]);
                setTableData(r.result);
            })
            .catch((_) => {})
            .finally(() => {
                setIsLoading(false);
            });

        // We don't want to have log as one of the dependencies as it will cause infinite loop.
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [kpi, keepDimensions, to, from]);

    React.useEffect(() => {
        let filteredTableData = tableData;
        if (filterDimension.length > 0) {
            filteredTableData = tableData.filter((value) => filterDimension.includes(value.dimension));
        }
        setFilteredTableData(filteredTableData);

        const series: SeriesEntry[] = generateEmptySeriesDataForRange(from, to);

        filteredTableData.forEach((value) => {
            const date = new Date(value.date).setHours(0, 0, 0, 0);
            const existingItem = series.find((item) => item.d === date);

            if (existingItem) {
                if (keepDimensions) {
                    existingItem[value.dimension] = (existingItem[value.dimension] ?? 0) + value.count;
                } else {
                    existingItem.count = (existingItem.count ?? 0) + value.count;
                }
            } else {
                const item: SeriesEntry = {
                    d: date,
                };
                if (keepDimensions) {
                    item[value.dimension] = value.count;
                } else {
                    item.count = value.count;
                }
                series.push(item);
            }
        });

        setChartData(series);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [tableData, filterDimension]);

    const downloadXSLX = () => {
        const data = filteredTableData.map((record) => {
            return {
                Datum: dateFormatter(new Date(record.date)),
                [kpi.dimension]: record.dimension,
                [kpi.value]: record.count,
            };
        });

        const now = new Date();
        const dateTimeString = [
            now.getFullYear(),
            (now.getMonth() + 1).toString().padStart(2, '0'),
            now.getDate().toString().padStart(2, '0'),
            now.getHours().toString().padStart(2, '0'),
            now.getMinutes().toString().padStart(2, '0'),
            now.getSeconds().toString().padStart(2, '0'),
        ].join('');

        // eslint-disable-next-line
        const worksheet = XLSX.utils.json_to_sheet(data);
        // eslint-disable-next-line
        const workbook = XLSX.utils.book_new();
        // eslint-disable-next-line
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Data');
        // eslint-disable-next-line
        XLSX.writeFileXLSX(workbook, `${dateTimeString} ${kpi.label}.xlsx`, { compression: true });
    };

    return (
        <div className={classes.root}>
            <div className={classes.header}>
                <div className={classes.title}>{kpi.label}</div>
            </div>
            {!isLoading && (
                <div>
                    <ResponsiveContainer width="100%" height={300}>
                        <LineChart data={chartData} margin={{ top: 4, right: 24 }}>
                            <XAxis
                                dataKey="d"
                                scale="time"
                                type="number"
                                domain={[from.setHours(0, 0, 0, 0), to.setHours(0, 0, 0, 0)]}
                                tickFormatter={tickFormatter}
                                padding="gap"
                            />
                            <YAxis allowDecimals={false} />
                            <CartesianGrid strokeDasharray="3 3" />
                            <ChartTooltip
                                labelFormatter={labelFormatter}
                                content={<TopTenTooltip />}
                                wrapperStyle={{ zIndex: 9999 }}
                            />
                            {chartData.length &&
                                getSeriesListFromChartData(chartData).map((key, index) => (
                                    <Line key={key} type="monotone" dataKey={key} stroke={getColor(index)} />
                                ))}
                        </LineChart>
                    </ResponsiveContainer>

                    <Divider className={classes.divider} />

                    <div className={classes.tableToolbar}>
                        <div style={{ flexGrow: 1 }}></div>
                        <Filtering
                            options={[...new Set(tableData.map((v) => v.dimension))]}
                            onChange={setFilterDimension}
                        />
                        <Tooltip content="Tabelle als Excel Datei herunterladen" relationship="label" withArrow>
                            <Button
                                icon={<ArrowDownloadRegular />}
                                onClick={() => {
                                    downloadXSLX();
                                }}
                            >
                                .xlsx
                            </Button>
                        </Tooltip>
                    </div>

                    <Table size="small">
                        <TableHeader>
                            <TableRow>
                                <TableHeaderCell>Datum</TableHeaderCell>
                                <TableHeaderCell>{kpi.dimension}</TableHeaderCell>
                                <TableHeaderCell>{kpi.value}</TableHeaderCell>
                            </TableRow>
                        </TableHeader>
                        <TableBody>
                            {filteredTableData.map((row) => (
                                <TableRow key={`${row.date}-${row.dimension}`}>
                                    <TableCell>{dateFormatter(new Date(row.date))}</TableCell>
                                    <TableCell>{row.dimension}</TableCell>
                                    <TableCell>{row.count}</TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </div>
            )}
            {isLoading && <Loading text="Lade Daten ..." />}
        </div>
    );
};

const TopTenTooltip: React.FC<ChartTooltipProps<number, string>> = ({ active, payload, label }) => {
    if (active && payload?.length) {
        // Sort the payload data and take the top 10 items
        const top10 = payload.sort((a, b) => (b.value as number) - (a.value as number)).slice(0, 10);

        return (
            <div style={{ border: '1px solid #888', background: '#fff', padding: '0 16px 8px' }}>
                <p className="label">{labelFormatter(Number(label))}</p>
                <p>Summe: {top10.map((entry) => entry.value ?? 0).reduce((acc, cur) => acc + cur, 0)}</p>
                <p>Top 10:</p>
                {top10.map((entry, index) => (
                    <p key={`item-${index}`} style={{ margin: '2px 0' }}>{`${entry.name}: ${entry.value}`}</p>
                ))}
            </div>
        );
    }

    return null;
};

interface FilteringProps {
    options: string[];
    onChange: (selected: string[]) => void;
}

export const Filtering: React.FC<FilteringProps> = ({ options, onChange }) => {
    const [query, setQuery] = React.useState<string>('');
    const [selectedOptions, setSelectedOptions] = React.useState<string[]>([]);
    const onOptionSelect: TagPickerProps['onOptionSelect'] = (_, data) => {
        if (data.value === 'no-matches') {
            onChange([]);
            return;
        }
        setSelectedOptions(data.selectedOptions);
        onChange(data.selectedOptions);
        setQuery('');
    };

    const children = useTagPickerFilter({
        query,
        options,
        noOptionsElement: <TagPickerOption value="no-matches">Kein Eintrag vorhanden</TagPickerOption>,
        renderOption: (option) => (
            <TagPickerOption
                key={option}
                media={<Avatar shape="square" aria-hidden name={option} color="colorful" />}
                value={option}
            >
                {option}
            </TagPickerOption>
        ),

        filter: (option) => !selectedOptions.includes(option) && option.toLowerCase().includes(query.toLowerCase()),
    });
    return (
        <TagPicker onOptionSelect={onOptionSelect} selectedOptions={selectedOptions}>
            <TagPickerControl>
                <TagPickerGroup>
                    {selectedOptions.map((option) => (
                        <Tag
                            key={option}
                            shape="rounded"
                            media={<Avatar aria-hidden name={option} color="colorful" />}
                            value={option}
                        >
                            {option}
                        </Tag>
                    ))}
                </TagPickerGroup>
                <TagPickerInput
                    value={query}
                    onChange={(e) => {
                        setQuery(e.target.value);
                    }}
                    clearable
                    placeholder={!selectedOptions.length ? 'Filter' : ''}
                />
            </TagPickerControl>

            <TagPickerList>{children}</TagPickerList>
        </TagPicker>
    );
};
