import { BaseService } from './BaseService';

interface FeedbackResponse {
    isSuccess: boolean;
}

export class FeedbackService extends BaseService {
    submitFeedback = async (
        chatId: string,
        messageId: string,
        score: number,
        comment: string,
        user: string,
        accessToken: string,
    ): Promise<FeedbackResponse> => {
        const result = await this.getResponseAsync<FeedbackResponse>(
            {
                commandPath: `chats/${chatId}/feedback`,
                method: 'POST',
                body: {
                    messageId,
                    score,
                    comment,
                    user,
                },
            },
            accessToken,
        );

        return result;
    };
}
