import { DismissRegular } from '@fluentui/react-icons';
import {
    makeStyles,
    MessageBar,
    MessageBarActions,
    MessageBarBody,
    Button,
    shorthands,
} from '@fluentui/react-components';
import { useAppDispatch, useAppSelector } from '../../redux/app/hooks';
import { dismissDisclaimer } from '../../redux/features/app/appSlice';
import { RootState } from '../../redux/app/store';

const useClasses = makeStyles({
    messageBar: {
        ...shorthands.borderWidth('0', '0', '1px'),
        ...shorthands.borderColor('rgb(0 0 0 / 10%)'),
    },
});

export const ChatDisclaimer = () => {
    const classes = useClasses();
    const dispatch = useAppDispatch();

    const { showDisclaimer } = useAppSelector((state: RootState) => state.app);

    return (
        <>
            {showDisclaimer && (
                <MessageBar className={classes.messageBar} shape="square" intent="warning">
                    <MessageBarBody>
                        Der X-Guide ist nicht für die Verarbeitung personenbezogener Daten zugelassen. Bitte gebe keine
                        personenbezogenen Daten Dritter ein.
                    </MessageBarBody>
                    <MessageBarActions
                        containerAction={
                            <Button
                                aria-label="dismiss"
                                appearance="transparent"
                                icon={<DismissRegular />}
                                onClick={() => {
                                    dispatch(dismissDisclaimer());
                                }}
                            />
                        }
                    ></MessageBarActions>
                </MessageBar>
            )}
        </>
    );
};
