import { Card, CardPreview, Text, Tooltip, makeStyles, shorthands, tokens } from '@fluentui/react-components';
import React, { useState } from 'react';
import { GetResponseOptions } from '../../libs/hooks/useChat';
import { AlertType } from '../../libs/models/AlertType';
import { ChatMessageType } from '../../libs/models/ChatMessage';
import { useAppDispatch, useAppSelector } from '../../redux/app/hooks';
import { RootState } from '../../redux/app/store';
import { addAlert } from '../../redux/features/app/appSlice';
import { editConversationInput, updateBotResponseStatus } from '../../redux/features/conversations/conversationsSlice';
import { useLog } from '../../libs/hooks';
import { LogEventType } from '../../libs/services/LogService';
import { Preset } from '../../libs/services/SettingsService';
import { useSettings } from '../../libs/hooks/useSettings';

interface ChatPresetsProps {
    addMessage: (options: GetResponseOptions) => Promise<void>;
}

const useStyles = makeStyles({
    root: {
        display: 'flex',
        flexDirection: 'column',
        maxWidth: '105em',
        width: '100%',
    },
    header: {
        marginTop: '20px',
    },
    container: {
        display: 'flex',
        flexWrap: 'wrap',
        ...shorthands.gap('12px'),
        ...shorthands.padding('24px'),
        justifyContent: 'center',
    },
    card: {
        width: '200px',
        maxWidth: '200px',
        minHeight: '62px',
        display: 'table',
        boxShadow: tokens.shadow2,
        backgroundColor: tokens.colorNeutralBackground2,
        ':hover': {
            boxShadow: tokens.shadow2,
        },
    },
    smallRadius: {
        ...shorthands.borderRadius(tokens.borderRadiusSmall),
    },
    cardPreview: {
        display: 'table-cell',
        textAlign: 'center',
        verticalAlign: 'middle',
        lineHeight: 'normal',
        wordWrap: 'break-word',
    },
});

export const ChatPresets: React.FC<ChatPresetsProps> = ({ addMessage }) => {
    const classes = useStyles();

    const settings = useSettings();
    const log = useLog();

    const dispatch = useAppDispatch();
    const { selectedId } = useAppSelector((state: RootState) => state.conversations);

    const [presets, setPresets] = useState<Preset[]>([]);

    React.useEffect(() => {
        settings
            .getPresets()
            .then((presets) => {
                setPresets(presets);
            })
            .catch((_) => {});

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleSubmit = (preset: Preset, messageType: ChatMessageType = ChatMessageType.Message) => {
        if (preset.content.trim() === '') {
            return;
        }

        log.writeLog(LogEventType.PresetUsed, preset.label).catch((_) => {});

        dispatch(editConversationInput({ id: selectedId, newInput: '' }));
        dispatch(updateBotResponseStatus({ chatId: selectedId, status: 'Calling the kernel' }));
        addMessage({ value: preset.content, messageType, chatId: selectedId }).catch((error) => {
            const message = `Error submitting chat input: ${(error as Error).message}`;
            dispatch(
                addAlert({
                    type: AlertType.Error,
                    message,
                }),
            );
        });
    };

    // make all flex boxes same height
    const containerRef = React.useRef<HTMLDivElement>(null);
    React.useEffect(() => {
        if (!containerRef.current) {
            return;
        }

        let maxHeight = 0;
        containerRef.current.querySelectorAll<HTMLDivElement>('.fui-Card').forEach((el) => {
            maxHeight = Math.max(maxHeight, el.getBoundingClientRect().height);
        });

        containerRef.current.querySelectorAll<HTMLDivElement>('.fui-Card').forEach((el) => {
            el.style.height = `${maxHeight}px`;
        });
    }, [presets]);

    return (
        <>
            {presets.length > 0 && (
                <div className={classes.root}>
                    <Text align="center" size={400} weight="semibold" className={classes.header}>
                        Wozu möchtest Du mehr erfahren?
                    </Text>
                    <div className={classes.container} ref={containerRef}>
                        {presets.map((preset, index) => {
                            return (
                                <div key={index}>
                                    <Card
                                        className={classes.card}
                                        appearance="filled"
                                        onClick={() => {
                                            handleSubmit(preset);
                                        }}
                                    >
                                        <Tooltip
                                            appearance="inverted"
                                            content={preset.content}
                                            relationship="description"
                                            positioning="after"
                                            withArrow
                                        >
                                            <CardPreview className={classes.cardPreview}>{preset.label}</CardPreview>
                                        </Tooltip>
                                    </Card>
                                </div>
                            );
                        })}
                    </div>
                    <Text align="center" size={300} italic>
                        ...oder stelle einfach Deine Frage.
                    </Text>
                </div>
            )}
        </>
    );
};
