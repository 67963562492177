import { Button, Input, makeStyles, Select, SelectProps, shorthands, tokens } from '@fluentui/react-components';
import React from 'react';
import { KpiPanel, KpiPanelProps } from './KpiPanel';
import { LogEventType } from '../../libs/services/LogService';
import { SharedStyles } from '../../styles';

const useClasses = makeStyles({
    root: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        height: '100%',
        backgroundColor: tokens.colorNeutralBackground1,
    },
    header: {
        ...shorthands.borderBottom('1px', 'solid', 'rgb(0 0 0 / 10%)'),
        ...shorthands.padding(tokens.spacingVerticalS, tokens.spacingHorizontalM),
        backgroundColor: tokens.colorNeutralBackground1,
        display: 'flex',
        flexDirection: 'row',
        boxSizing: 'border-box',
        width: '100%',
        justifyContent: 'space-between',
    },
    title: {
        ...shorthands.gap(tokens.spacingHorizontalM),
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'row',
        fontWeight: tokens.fontWeightSemibold,
    },
    controls: {
        display: 'flex',
        alignItems: 'center',
        ...shorthands.gap('4px'),
    },
    scroll: {
        display: 'flex',
        flexWrap: 'wrap',
        ...shorthands.gap('12px'),
        ...shorthands.margin(tokens.spacingVerticalXS),
        ...SharedStyles.scroll,
    },
    info: {
        ...shorthands.margin('auto'),
    },
});

export interface KpiDefinition {
    type: LogEventType;
    label: string;
    dimension: string;
    value: string;
}

const KpiList: KpiDefinition[] = [
    { type: LogEventType.SessionCreated, label: 'Neue Chats', dimension: 'Benutzer', value: 'Anzahl' },
    { type: LogEventType.MessageQueried, label: 'Nachricht geschickt', dimension: 'Benutzer', value: 'Anzahl' },
    { type: LogEventType.UserActive, label: 'Aktive Benutzer', dimension: 'Benutzer', value: 'Anzahl' },
    { type: LogEventType.PresetUsed, label: 'Hotbutton benutzt', dimension: 'Button', value: 'Anzahl' },
    { type: LogEventType.ReferenceOpened, label: 'Referenz geöffnet', dimension: 'Referenz', value: 'Anzahl' },
    { type: LogEventType.ReferenceUsed, label: 'Referenz für Antwort benutzt', dimension: 'Referenz', value: 'Anzahl' },
];

function formatDateString(date: Date) {
    return `${date.getFullYear()}-${(date.getMonth() + 1).toString().padStart(2, '0')}-${date.getDate().toString().padStart(2, '0')}`;
}

export const KpiWindow: React.FC = () => {
    const classes = useClasses();

    const minDate = new Date();
    minDate.setDate(minDate.getDate() - 90);
    const maxDate = new Date();

    const [selectedKpi, setSelectedKpi] = React.useState(KpiList[0]);
    const [fromDate, setFromDate] = React.useState(() => {
        const today = new Date();
        const sevenDaysAgo = new Date(today);
        sevenDaysAgo.setDate(today.getDate() - 7);
        return sevenDaysAgo;
    });
    const [toDate, setToDate] = React.useState(maxDate);
    const [kpiPanelProps, setKpiPanelProps] = React.useState(null as KpiPanelProps | null);

    const onKpiSelect: SelectProps['onChange'] = (_, data) => {
        setSelectedKpi(KpiList.find((i) => i.type === (data.value as LogEventType)) ?? KpiList[0]);
    };

    const updateKpiPanel = () => {
        const finalToDate = new Date();
        finalToDate.setDate(toDate.getDate() + 1);

        setKpiPanelProps({
            kpi: selectedKpi,
            from: fromDate,
            to: finalToDate,
        });
    };

    return (
        <div className={classes.root}>
            <div className={classes.header}>
                <div className={classes.title}>Statistiken</div>
                <div className={classes.controls}>
                    <Select onChange={onKpiSelect}>
                        {KpiList.map((i) => (
                            <option key={i.type} value={i.type}>
                                {i.label}
                            </option>
                        ))}
                    </Select>
                    <Input
                        type="date"
                        contentBefore={<span>von</span>}
                        min={formatDateString(minDate)}
                        max={formatDateString(maxDate)}
                        value={formatDateString(fromDate)}
                        onChange={(_, data) => {
                            setFromDate(new Date(`${data.value}`));
                        }}
                    />
                    <Input
                        type="date"
                        contentBefore={<span>bis</span>}
                        min={formatDateString(minDate)}
                        max={formatDateString(maxDate)}
                        value={formatDateString(toDate)}
                        onChange={(_, data) => {
                            setToDate(new Date(`${data.value}`));
                        }}
                    />
                    <Button appearance="primary" onClick={updateKpiPanel}>
                        Anzeigen
                    </Button>
                </div>
            </div>
            <div className={classes.scroll}>
                {kpiPanelProps && <KpiPanel kpi={kpiPanelProps.kpi} from={kpiPanelProps.from} to={kpiPanelProps.to} />}
                {!kpiPanelProps && (
                    <div className={classes.info}>KPI und Zeitraum angeben, und dann auf Anzeigen klicken.</div>
                )}
            </div>
        </div>
    );
};
