import { FC } from 'react';
import { useAppSelector } from '../../redux/app/hooks';
import { RootState } from '../../redux/app/store';
import { AppState } from '../../App';
import { makeStyles, shorthands, Subtitle1, tokens } from '@fluentui/react-components';
import { FeatureKeys } from '../../redux/features/app/AppState';
import { PluginGallery } from '../open-api-plugins/PluginGallery';
import { UserMenu } from './UserMenu';

export const useClasses = makeStyles({
    root: {
        alignItems: 'center',
        backgroundColor: '#08204A', //tokens.colorBrandForeground2,
        color: tokens.colorNeutralForegroundOnBrand,
        display: 'flex',
        '& h1': {
            paddingLeft: tokens.spacingHorizontalXL,
            display: 'flex',
        },
        height: '64px',
        justifyContent: 'space-between',
        width: '100%',
    },
    persona: {
        marginRight: tokens.spacingHorizontalXXL,
    },
    cornerItems: {
        display: 'flex',
        ...shorthands.gap(tokens.spacingHorizontalS),
    },
});

interface IHeaderParams {
    appState: AppState;
    setAppState: (state: AppState) => void;
}

export const Header: FC<IHeaderParams> = ({ appState, setAppState }) => {
    const classes = useClasses();

    const { features } = useAppSelector((state: RootState) => state.app);

    return (
        <div className={classes.root}>
            <Subtitle1 as="h1">X-Guide</Subtitle1>
            {appState > AppState.SettingUserInfo && (
                <div className={classes.cornerItems}>
                    <div className={classes.cornerItems}>
                        {features[FeatureKeys.PluginsPlannersAndPersonas].enabled && <PluginGallery />}
                        {
                            <UserMenu
                                setLoadingState={() => {
                                    setAppState(AppState.SigningOut);
                                }}
                            />
                        }
                    </div>
                </div>
            )}
        </div>
    );
};
