import React, { useState } from 'react';
import { FixedResponse } from '../../libs/services/SettingsService';
import { Button, Input, makeStyles, shorthands, tokens } from '@fluentui/react-components';
import { AddRegular, DeleteRegular } from '@fluentui/react-icons';

export interface EditableFixedResponsesListProps {
    initialItems: FixedResponse[];
    onItemsChange: (items: FixedResponse[]) => void;
}

const useClasses = makeStyles({
    root: {
        display: 'flex',
        flexDirection: 'column',
        ...shorthands.gap(tokens.spacingVerticalS),
    },
    row: {
        display: 'flex',
        flexDirection: 'row',
        ...shorthands.gap(tokens.spacingHorizontalS),
    },
    input: {
        flexGrow: 1,
        maxWidth: 'auto',
    },
});

export const EditableFixedResponsesList: React.FC<EditableFixedResponsesListProps> = ({
    initialItems,
    onItemsChange,
}) => {
    const classes = useClasses();

    const [items, setItems] = useState(initialItems);

    React.useEffect(() => {
        onItemsChange(items);
    }, [items, onItemsChange]);

    const handleInputChange = (index: number, event: React.ChangeEvent<HTMLInputElement>) => {
        const newItems = [...items];
        newItems[index][event.target.name as keyof FixedResponse] = event.target.value;
        setItems(newItems);
    };

    const handleAddItem = () => {
        setItems([...items, { input: '', output: '' }]);
    };

    const handleRemoveItem = (index: number) => {
        const newItems = items.filter((_, i) => i !== index);
        setItems(newItems);
    };

    return (
        <div className={classes.root}>
            {items.map((item, index) => (
                <div key={index} className={classes.row}>
                    <Input
                        className={classes.input}
                        name="input"
                        value={item.input}
                        onChange={(event) => {
                            handleInputChange(index, event);
                        }}
                        placeholder="Input"
                        autoComplete="off"
                    />
                    <Input
                        className={classes.input}
                        name="output"
                        value={item.output}
                        onChange={(event) => {
                            handleInputChange(index, event);
                        }}
                        placeholder="Output"
                        autoComplete="off"
                    />
                    <Button
                        icon={<DeleteRegular />}
                        onClick={() => {
                            handleRemoveItem(index);
                        }}
                    />
                </div>
            ))}
            <Button icon={<AddRegular />} appearance="subtle" onClick={handleAddItem}>
                Weitere Antwort
            </Button>
        </div>
    );
};
