import {
    Button,
    Dialog,
    DialogActions,
    DialogBody,
    DialogContent,
    DialogSurface,
    DialogTitle,
    DialogTrigger,
    Input,
    Label,
    Text,
    Tooltip,
    makeStyles,
} from '@fluentui/react-components';
import { UserFeedback } from '../../../libs/models/ChatMessage';
import { useAppDispatch, useAppSelector } from '../../../redux/app/hooks';
import { RootState } from '../../../redux/app/store';
import { updateMessageProperty } from '../../../redux/features/conversations/conversationsSlice';
import { ThumbDislike16, ThumbLike16 } from '../../shared/BundledIcons';
import { useChat } from '../../../libs/hooks';
import { IChatMessage } from '../../../libs/models/ChatMessage';
import { useState } from 'react';
import { addAlert } from '../../../redux/features/app/appSlice';
import { AlertType } from '../../../libs/models/AlertType';
import debug from 'debug';
import { Constants } from '../../../Constants';

const log = debug(Constants.debug.root).extend('feedback');

const useClasses = makeStyles({
    root: {
        display: 'flex',
        'place-content': 'flex-end',
        alignItems: 'center',
    },
    dialogContent: {
        display: 'flex',
        flexDirection: 'column',
        rowGap: '10px',
    },
});

interface IUserFeedbackProps {
    messageIndex: number;
    message: IChatMessage;
}

export const UserFeedbackActions: React.FC<IUserFeedbackProps> = ({ messageIndex, message }) => {
    const classes = useClasses();

    const { activeUserInfo } = useAppSelector((state: RootState) => state.app);
    const { submitFeedback } = useChat();

    const dispatch = useAppDispatch();
    const { selectedId } = useAppSelector((state: RootState) => state.conversations);
    const [comment, setComment] = useState('');
    const [score, setScore] = useState(0);

    const handleSubmit = (ev: React.FormEvent) => {
        ev.preventDefault();

        if (!message.id || comment.trim() == '' || score == 0) {
            return;
        }

        submitFeedback(selectedId, message.id, score, comment, activeUserInfo?.email ?? '')
            .then(() => {
                dispatch(
                    addAlert({
                        type: AlertType.Success,
                        message: 'Feedback erfolgreich gesendet',
                    }),
                );
            })
            .catch((error) => {
                const message = `Error submitting feedback: ${(error as Error).message}`;
                log(message);
                dispatch(
                    addAlert({
                        type: AlertType.Error,
                        message,
                    }),
                );
            });

        dispatch(
            updateMessageProperty({
                chatId: selectedId,
                messageIdOrIndex: messageIndex,
                property: 'userFeedback',
                value: UserFeedback.Negative,
                frontLoad: true,
            }),
        );
    };

    return (
        <div className={classes.root}>
            <Text color="gray" size={200}>
                Antwort bewerten
            </Text>
            <Dialog>
                <DialogTrigger disableButtonEnhancement>
                    <div>
                        <Tooltip content={'Gute Antwort'} relationship="label">
                            <Button
                                icon={<ThumbLike16 />}
                                appearance="transparent"
                                aria-label="Edit"
                                onClick={() => {
                                    setScore(5);
                                }}
                            />
                        </Tooltip>
                        <Tooltip content={'Schlechte Antwort'} relationship="label">
                            <Button
                                icon={<ThumbDislike16 />}
                                appearance="transparent"
                                aria-label="Edit"
                                onClick={() => {
                                    setScore(1);
                                }}
                            />
                        </Tooltip>
                    </div>
                </DialogTrigger>
                <DialogSurface aria-describedby={undefined}>
                    <form autoComplete="off" onSubmit={handleSubmit}>
                        <DialogBody>
                            <DialogTitle>Feedback zur Antwort</DialogTitle>
                            <DialogContent className={classes.dialogContent}>
                                <Label required htmlFor={'comment'}>
                                    Kommentar
                                </Label>
                                <Input
                                    required
                                    type="text"
                                    id={'comment'}
                                    onChange={(_event, data) => {
                                        setComment(data.value);
                                    }}
                                />
                            </DialogContent>
                            <DialogActions>
                                <DialogTrigger disableButtonEnhancement>
                                    <Button appearance="secondary">Abbrechen</Button>
                                </DialogTrigger>
                                <Button type="submit" appearance="primary">
                                    Absenden
                                </Button>
                            </DialogActions>
                        </DialogBody>
                    </form>
                </DialogSurface>
            </Dialog>
        </div>
    );
};
