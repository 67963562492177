import { useMsal } from '@azure/msal-react';
import { AuthHelper } from '../auth/AuthHelper';
import { Settings, SettingsService } from '../services/SettingsService';

export const useSettings = () => {
    const { instance, inProgress } = useMsal();
    const settingsService = new SettingsService();

    const save = async (settings: Settings) => {
        await settingsService.save(settings, await AuthHelper.getSKaaSAccessToken(instance, inProgress));
    };

    const load = async () => {
        return await settingsService.load(await AuthHelper.getSKaaSAccessToken(instance, inProgress));
    };

    const getPresets = async () => {
        return await settingsService.getPresets(await AuthHelper.getSKaaSAccessToken(instance, inProgress));
    };

    return {
        save,
        load,
        getPresets,
    };
};
