import React, { useRef } from 'react';
import { Settings } from '../../libs/services/SettingsService';
import { Button } from '@fluentui/react-components';
import { ArrowExportLtrRegular, ArrowImportRegular } from '@fluentui/react-icons';

export interface ImportProps {
    onImport: (settings: Settings) => void;
}

export const Import: React.FC<ImportProps> = ({ onImport }) => {
    const fileInputElementRef = useRef<HTMLInputElement>(null);

    const onChange = () => {
        const inputElement = fileInputElementRef.current;
        if (!inputElement) {
            return;
        }

        const file = inputElement.files?.item(0);
        if (!file) {
            return;
        }

        const reader = new FileReader();
        reader.onload = (ev) => {
            try {
                const jsonData = JSON.parse(ev.target?.result as string) as Settings;
                onImport(jsonData);
            } catch (_) {
            } finally {
                inputElement.value = '';
            }
        };

        reader.readAsText(file);
    };

    return (
        <>
            <input
                ref={fileInputElementRef}
                type="file"
                accept="application/json"
                onChange={onChange}
                style={{ display: 'none' }}
            />
            <Button
                icon={<ArrowImportRegular />}
                onClick={() => {
                    fileInputElementRef.current?.click();
                }}
            >
                Import
            </Button>
        </>
    );
};

export interface ExportProps {
    settings: Settings;
}

export const Export: React.FC<ExportProps> = ({ settings }) => {
    const handleExport = () => {
        const blob = new Blob([JSON.stringify(settings, null, 2)], { type: 'application/json' });
        const url = URL.createObjectURL(blob);
        const el = document.createElement('a');
        el.setAttribute('href', url);
        el.setAttribute('download', `${window.location.hostname}.settings.json`);
        el.click();
    };

    return (
        <Button icon={<ArrowExportLtrRegular />} onClick={handleExport}>
            Export
        </Button>
    );
};
